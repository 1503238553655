.what-is-codidact {
    margin: 50px auto 80px auto;
    display: flex;
    justify-content: center;

    @include for-tablet-landscape-up {
        left: -10%;
    }

    @include for-phone-only {
        margin-top: 0;
    }

    div {
        height: fit-content;
        max-width: 500px;

        .heading--secondary {
            color: $color-blue;
            margin: 0 0 30px 0;
        }
    }

    img {
        @include for-image-adjustments {
            display: none;
        }
        width: 40%;
    }
}
