.heading {
    &--primary {
        font-size: $has-large-font-size;
        font-weight: $has-black-weight;
        letter-spacing: 1.2px;
        line-height: 1;
        margin: 0;
        padding: 0;

        @include for-tablet-landscape-up {
            font-size: $has-x-large-font-size--mobile;
            letter-spacing: 1.2px;
        }

        @include for-desktop-up {
            font-size: $has-x-large-font-size;
            font-weight: $has-black-weight;
            letter-spacing: 2px;
        }
    }

    &--secondary {
        font-size: $has-large-font-size;
        font-weight: $has-regular-weight;
        margin: 0;
        padding: 0;
    }

    &--tertiary {
        font-size: $has-medium-font-size;
        font-weight: $has-regular-weight;
        margin: 0;
        padding: 0;
    }
}

p {
    font-size: $has-normal-font-size;
    font-weight: $has-regular-weight;
    margin: 0;
    padding: 0;
    line-height: 1.8;
    letter-spacing: 1px;
}

strong {
    font-weight: $has-bold-weight;
}
