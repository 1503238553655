.signup {
    .container {
        max-width: 400px;
        margin: 0 auto;
    }

    .heading--tertiary {
        text-align: center;
        margin-bottom: 40px;
    }

    margin-bottom: 120px;
}
