.bar {
    width: 15px;
    height: 6px;
    border-radius: 2px;
    background-color: $loading-bar-background;
}

.loader .bar {
    animation: loading 2s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
    margin-top: 20px;
}

.loader { display: none; }

@keyframes loading {
    0%,
    100% { transform: translate(0, 0); }

    50% {
        transform: translate(80px, 0);
        background-color: $color-white;
        width: 25px;
    }
}
