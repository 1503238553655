@import "variables.scss";

.header {
    padding-bottom: $gutter;
    padding-top: $gutter;
    position: absolute;

    .links,
    .logo {
        position: relative;
        z-index: 10;
    }

    .logo {
        @include for-image-adjustments {
            display: block;
            margin: 0px auto;
            margin-bottom: 10px;
        }
    }

    .links {
        display: inline-block;
        height: 100%;
        vertical-align: top;
        margin-top: 10px;
        margin-left: 2%;
        justify-content: space-evenly;

        @include for-image-adjustments {
            width: 100%;
            text-align: center;
            margin-top: 40px;
            margin-left: 0;
            display: flex;
            justify-content: space-evenly;
        }

        a {
            margin-left: 50px;
            font-size: 20px;
            text-decoration: none;
            position: relative;
            color: $color-black;

            @include for-image-adjustments {
                margin-left: 0px;
            }

            &:before {
                content: "";
                position: absolute;
                width: 0;
                height: 1px;
                bottom: 0;
                left: 0;
                width: 100%;
                background-color: #2F80ED;
                visibility: visible;
                transition: all 0.3s ease-in-out;
            }

            &:hover {
                &:before {
                    visibility: hidden;
                    width: 0;
                }
            }
        }
    }
}
