$primary-font: "Red Hat Display", sans-serif;

$has-normal-font-size: 18px; // this is defined as the root unit on the html element
$has-small-font-size: 0.8rem;
$has-medium-font-size: 2rem;
$has-large-font-size: 3rem;
$has-x-large-font-size--mobile: 4rem;
$has-x-large-font-size: 5.2rem;

$has-regular-weight: 400;
$has-bold-weight: 500;
$has-xbold-weight: 700;
$has-black-weight: 900;
