.community-first {
    background: $color-blue;
    display: flex;
    position: relative;
    justify-content: center;
    padding-bottom: 100px;
    padding-top: 100px;

    .community-first--container {
        max-width: 50%;

        @include for-image-adjustments {
            max-width: 90%;
        }
    }

    .heading--secondary {
        margin: 40px 0 30px 0;
        color: $color-white;
    }

    p {
        color: $color-white;
        width: 100%;
        a {
            color: $color-white;
            text-decoration: underline;
        }
    }

    img {
        @include for-image-adjustments {
            display: none;
        }

        margin-right: 20px;
        width: 30%;
    }
}

.call-to-action {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);
    background: $color-light-blue;
    padding-top: 100px;
    padding-bottom: 100px;

    .call-to-action--container {
        max-width: 700px;
        position: relative;
        display: block;
        margin: 0px auto;

        p {
            color: $color-white;
            margin-bottom: 20px;
        }
    }
}
