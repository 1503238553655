$color-black: #2b2b2b;
$color-white: white;
$color-yellow: #ffc601;
$color-light-yellow: #f2c94f;
$color-orange: #ff782f;
$color-blue: #575aff;
$color-warning: #f1c40f;
$color-light-blue: #3498db;
$color-gradient-alternate: linear-gradient(247.06deg, $color-yellow 16.55%, $color-orange 90.3%);
$button-background: $color-blue;
$loading-bar-background: #4b9cdb;
