.hero {
    display: flex;
    vertical-align: middle;
    justify-content: center;
    position: relative;

    .semi-circle {
        height: 100%;
        position: absolute;
        right: 0;
        z-index: 4;

        @include for-image-adjustments {
            display: none;
        }
    }

    .grid--hero {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 50% 50%;
        width: 90%;
        grid-auto-rows: 1fr;
        height: 100%;

        .grid--hero-text {
            padding: 80px 0 20px 0;
            text-align: left;
            grid-column: 1 / 2;
            grid-row: 1 / 2;

            @include for-image-adjustments {
                text-align: center;
                grid-column: 1 / 3;
                grid-row: 1 / 3;
                padding: 80px 0 20px 0;
            }

            h1 {
                margin-top: 30%;
            }

            button {
                padding: 30px 100px 30px 100px;
                background: $color-light-yellow;
                margin-top: 50px;
                color: black;

                @include for-image-adjustments {
                    display: block;
                    margin: 50px auto;
                }
            }
        }


        .grid--hero--big {
            position: relative;
            grid-column: 2 / 3;
            grid-row: 1 / 3;
            z-index: 6;
            align-items: flex-end;
            display: flex;
            justify-content: flex-start;

            @include for-image-adjustments {
                display: none;
            }

            img {
                position: relative;
                right: -10%;
                width: 100%;
            }
        }
    }
}
