.get-involved {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 100px;

    > .container {
        text-align: center;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: $column-gutter * 2;

        @include for-tablet-portrait-up {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: $column-gutter;
        }

        @include for-tablet-landscape-up {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .heading--secondary {
        margin-bottom: 100px;
    }

    .card {
        header img {
            width: 60px;
            height: auto;
        }

        &.meta img {
            height: 100%;
            filter: brightness(0) invert(1);
        }

        &.discourse img {
            filter: invert(100%);
            height: auto;
            width: 60px;
        }

        &.discord img {
            height: auto;
            width: 70px;

        }
    }
    

}
