@import "normalize.scss";
@import "variables.scss";
@import "grid.scss";
@import "typography.scss";

* {
    box-sizing: border-box;
}

html {
    font-family: $primary-font;
    font-size: $has-normal-font-size;
    background-color: rgb(229, 229, 229);
}

a {
    color: $color-blue;
    text-decoration: none;
}

.container {
    position: relative;
}


.button-container {
    margin: 0 auto;
    transition: box-shadow 0.3s ease-in-out, bottom 0.3s ease-in-out;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
    bottom: 0;
    position: relative;
    @include for-tablet-portrait-up {
       width: 340px;
    } 
    @include for-tablet-landscape-up {
       width: 390px;
    }
    &:hover {
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
        bottom: 4px;
    }
   

    header {
        width: 100%;
        background: $color-black;
        text-align: center;
        padding: 15px 0;
        height: 90px;
    }

    .card__heading {
        color: $color-black;
        font-size: 1.4rem;
        font-weight: 400;
        margin: 25px 20px;
    }

    .card__text {
        font-size: 0.8rem;
        letter-spacing: 0.5px;
        line-height: 1.5;
        margin: 0 20px 50px 20px;
    }
}


.footer {
    background-color: $color-black;
    margin-top: 60px;
}

.card {
    transition: box-shadow 0.3s ease-in-out, bottom 0.3s ease-in-out;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
    bottom: 0;
    position: relative;

    &:hover {
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
        bottom: 4px;
    }

    header {
        width: 100%;
        background: $color-black;
        text-align: center;
        padding: 15px 0;
        height: 90px;
    }

    .card__heading {
        color: $color-black;
        font-size: 1.4rem;
        font-weight: 400;
        margin: 25px 20px;
    }

    .card__text {
        font-size: 0.8rem;
        letter-spacing: 0.5px;
        line-height: 1.5;
        margin: 0 20px 50px 20px;
    }
}

.card--link {
    text-decoration: none;
    color: $color-black;
}

input {
    padding: 10px 20px 10px 20px;
    border: none;
    width: 100%;
    height: 50px;
    display: block;
    border-radius: 10px;
    min-width: 300px;
    color: $color-black;
    font-size: 20px;
    -webkit-appearance: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.45);
    background: white;
    outline: none;
}

button {
    /* iOS button padding bug fix */
    padding: 0;
    padding: 10px 20px 10px 20px;
    -webkit-appearance: none;
    border-radius: 20px;
    background: $button-background;
    display: block;
    transition: 0.2s all ease;
    font-size: 20px;
    will-change: opacity;
    color: white;
    cursor: pointer;
    border: none;
    outline: none;

    &:disabled {
        opacity: 0.3;
        box-shadow: none;
        pointer-events: none;
        cursor: not-allowed;
        transition: none;
    }

    &:hover {
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
    }
}

div#faq {
    h1 {
        display: none;
    }

    h2 {
        font-size: 20px;
    }
}

@import "loader.scss";
@import "sections/header.scss";
@import "sections/hero.scss";
@import "sections/get-involved.scss";
@import "sections/what-is-codidact.scss";
@import "sections/community-first.scss";
@import "sections/signup.scss";
@import "sections/faq.scss";
@import "sections/mailing-list.scss";
@import "sections/footer.scss";
@import "sections/donors.scss";
